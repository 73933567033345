@import '../../../../scss/theme-bootstrap';

.hero-carousel-formatter {
  width: 100%;
  display: block;
  position: relative;
  @media #{$cr19-large-up} {
    display: flex;
  }
  &__wrapper {
    width: 100%;
    margin-#{$ldirection}: 20px;
    margin-bottom: 36px;
    @media #{$cr19-large-up} {
      width: 65%;
      margin-#{$ldirection}: 0;
    }
    .slick-slider {
      margin-bottom: 0;
      .slick-list {
        @include swap_direction(padding, 0 75px 0 0);
        @media #{$cr19-large-up} {
          @include swap_direction(padding, 0 100px 0 0);
        }
        .slick-slide {
          .hero-block__content-over-media,
          .video-block__content-over-media {
            width: 100%;
            padding: 0 16px;
            .hero-block__link,
            .video-block__link {
              > a {
                display: inline-block;
              }
            }
          }
          .video-block__content-over-media {
            z-index: 9;
            position: absolute;
            bottom: 0;
            display: flex;
            flex-direction: column;
          }
          .hero-block__content-below-media,
          .video-block__content-below-media {
            padding-#{$rdirection}: 20px;
          }
        }
      }
    }
  }
  &__header {
    width: 100%;
    margin-bottom: 36px;
    @media #{$cr19-large-up} {
      display: flex;
      width: 35%;
    }
    &-align-top {
      @include heroBlockVerticalAlignment(0 20px, 0 28px, 0 36px, 0 44px, 0 64px, 0 72px);
    }
    &-align-center {
      @include heroBlockVerticalAlignment(auto 20px, auto 28px, auto 36px, auto 44px, auto 64px, auto 72px);
    }
    &-align-bottom {
      @include heroBlockVerticalAlignment(auto 20px 0, auto 28px 0, auto 36px 0, auto 44px 0, auto 64px 0, auto 72px 0);
    }
  }
  &__container {
    &-slide {
      margin-#{$rdirection}: 3px;
      &[tabindex='-1']:focus {
        outline: none !important;
      }
    }
    .videojs-video {
      padding-top: 125%;
    }
  }
  &__controls {
    width: 100%;
    .slick-prev {
      #{$ldirection}: auto;
      transform: rotate(180deg);
    }
    .slick-next {
      #{$rdirection}: 0;
    }
    .slick-next-button {
      #{$rdirection}: 0;
      margin-#{$rdirection}: 30px;
      @media #{$cr19-large-up} {
        margin-#{$rdirection}: 70px;
      }
    }
    .slick-prev-button {
      #{$ldirection}: auto;
      margin-#{$ldirection}: 30px;
      @media #{$cr19-large-up} {
        margin-#{$ldirection}: 0;
      }
    }
    .slick-prev-button,
    .slick-next-button {
      cursor: pointer;
      background: none;
      border: none;
      height: 20px;
      width: 20px;
      position: absolute;
      margin-top: 16px;
    }
  }
}
